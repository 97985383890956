import React, { useState } from "react";
import { sliderData } from "./data";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

import "./styles.scss";

function SamplePrevArrow(props) {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <img
        className="dbb-nextDKHero"
        src="https://res.cloudinary.com/judiscloud/image/upload/v1583679105/Subtract_1.svg"
        alt="prev"
      />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <img
        className="dbb-prevDKHero"
        src="https://res.cloudinary.com/judiscloud/image/upload/v1583679105/Subtract_1.svg"
        alt="next"
      />
    </div>
  );
}

function YoutubeSlider() {
  const [videoData, setVideoData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 3000,
    autoplay: true,
    speed: 500,
    dots: false,
    fadeIn: false,
    pauseOnHover: true,
    slidesToScroll: 1,
    arrows: true,
  };

  const setVideoHandler = (student) => {
    setVideoData(student);
    setShowVideo(true);
  };

  const hideModalHandler = () => {
    setShowVideo(false);
    setVideoData(null);
  };

  return (
    <div className="dbb-youtube-testimonial-container">
      <h2 className="dbb-experience-heading">
        Hear from our Alumni on their <br />
        Kraftshala Experience
      </h2>
      <div className="dbb-heroVideoWrap">
        <div className="dbb-hero-slides">
          <Slider {...settings}>
            {sliderData.map((item) => (
              <div className="dbb-hero-slide" key={item.id}>
                <div className="dbb-hero-slide-content">
                  <p className="dbb-hero-slide-content-heading">{item.title}</p>
                  <div className="dbb-hero-slide-divider" />
                  <p className="dbb-hero-slide-content-desc">
                    {item.description}
                  </p>
                </div>
                <div className="dbb-hero-slide-video">
                  <LazyLoad>
                    {/* <iframe
                      width="100%"
                      height="100%"
                      src={item.link}
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={item.id}
                    /> */}
                    <img
                      className="testimonial-yt-thumb"
                      src={item.youtubeImg}
                      alt={item.alt + " Kraftshala Bschool Course Review"}
                      title={item.alt + " Kraftshala Bschool Course Review"}
                      onClick={() => setVideoHandler(item)}
                      aria-hidden="true"
                    />
                  </LazyLoad>
                </div>
              </div>
            ))}
          </Slider>
          {showVideo ? (
            <React.Fragment>
              <div
                className="spec-mask spec-active"
                onClick={hideModalHandler}
                aria-hidden="true"
              ></div>
              <div className="spec-modal">
                <img
                  src="https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/cross-white.svg"
                  alt="cross icon"
                  className="spec-modal-cross"
                  onClick={hideModalHandler}
                  aria-hidden="true"
                />
                <iframe
                  width="100%"
                  height="100%"
                  src={videoData.link + "?autoplay=1"}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="youtube-player"
                ></iframe>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default YoutubeSlider;
