import React, { useEffect, useState } from "react";
import "./navbar.scss";

const NavBar = (props) => {
  const { content } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const isSSR = typeof window === "undefined";

    if (!isSSR) {
      const navbar = window.document.querySelector(".page-navbar");

      const handleScroll = () => {
        const newOffset = props?.offset ?? 630;

        if (window.pageYOffset >= newOffset) {
          navbar.classList.add("sticky-nav");
        } else {
          navbar.classList.remove("sticky-nav");
        }

        highlightNav(navbar);
      };

      if (navbar) {
        window.addEventListener("scroll", handleScroll);
      }

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [props.offset]);

  const highlightNav = (navbar) => {
    let section = document.querySelectorAll(".section");

    if (section) {
      let sections = {};
      let i = 0;

      Array.prototype.forEach.call(section, function (e) {
        sections[e.id] = e.offsetTop - 100;
      });
      let scrollPosition = window.document.documentElement.scrollTop;

      for (i in sections) {
        if (sections[i] <= scrollPosition + navbar.offsetHeight) {
          let selectedLinks = window.document.querySelectorAll(
            ".selected-link"
          );

          for (let i = 0; i < selectedLinks.length; i++) {
            // selectedLink.setAttribute("class", " ");
            selectedLinks[i]?.classList?.remove("selected-link");
          }

          let eles = window.document.querySelectorAll(`#${i}-nav`);

          for (let i = 0; i < eles.length; i++) {
            eles[i]?.classList?.add("selected-link");
          }
        }
      }
    }
  };

  const handleClick = (id) => {
    const navbar = window.document.querySelector(".page-navbar");

    const navBarHeight = navbar.offsetHeight;

    const element = document.getElementById(id).offsetTop - navBarHeight;

    window.scroll({ top: element, behavior: "smooth" });
    scrollFn(`${id}-nav`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const scrollFn = (id) => {
    // let element = document.getElementById(id);
    // if(element){
    //   element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    // }
  };

  const NavItem = ({ link, k }) => {
    if (link.formLink) {
      return (
        <div
          aria-hidden="true"
          key={k}
          id={`${link.id}-nav`}
          className={
            link.formLink
              ? "nav-apply "
              : "page-link " +
                (link.desktopHide ? "desktop-hide " : "") +
                (link.mobileHide ? "mobile-hide" : "")
          }
          onClick={() => scrollFn(`${link.id}-nav`)}
        >
          {link.name}
        </div>
      );
    }

    if (link.dropDown) {
      return (
        <ul className="mb-dropdown-nav__menu desktop-hide">
          <li className="dropwdown-parent-li">
            {link.name}
            <ul>
              {content
                .filter((o) => o.dropdownShow)
                .map((link) => {
                  return (
                    <>
                      <li id={`${link.id}-nav`}>
                        <a href={`/sales-course/#${link.id}`}>{link.name}</a>
                      </li>
                    </>
                  );
                })}
            </ul>
          </li>
        </ul>
      );
    }

    return (
      <div
        onClick={() => handleClick(link.id)}
        aria-hidden="true"
        key={k}
        id={`${link.id}-nav`}
        className={
          "page-link " +
          (link.desktopHide ? "desktop-hide " : "") +
          (link.mobileHide ? "mobile-hide" : "")
        }
      >
        {link.name}
      </div>
    );
  };

  return (
    <div className="page-navbar">
      {content.map((link, k) => {
        return <NavItem link={link} k={k} />;
      })}
    </div>
  );
};

export default NavBar;
